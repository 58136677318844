<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
        <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
    <div class="pa-5">
      <h2 class="text--grey">Question Paper</h2>
    </div>




    
    <v-card class="mx-2 my-4 pa-2">
      <v-row class="pt-4">

        <v-col cols="12" sm="2">
          <v-select rounded outlined :items="academic_year_list_filter" label="Academic Year" required
            v-model="selected_ay_filter" item-text="ay" item-value="id"></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select rounded outlined :items="semester_list_filter" label="Sem" required v-model="selected_sem_filter"
            item-text="sem" item-value="id"></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select rounded outlined :items="year_list_filter" label="Year" required v-model="selected_year_filter"
            item-text="year" item-value="id"></v-select>
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete rounded outlined :items="dept_list_filter" label="Deparment" required
            v-model="selected_dept_filter" item-text="name" item-value="id"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete rounded outlined :items="course_list_filter" label="course" required
            v-model="selected_course_filter" item-text="name" item-value="id"></v-autocomplete>
        </v-col>
      </v-row>

      <center>
        <hr style="width:70%;" />
      </center>

      <v-row justify="center">
        <v-col cols="12" sm="3">
          <v-autocomplete solo clearable label="Question Paper Name" required item-text="questionpapername"
            item-value="questionpapername" v-model="question_paper_name_filter"
            @input.native="search_question_paper($event.srcElement.value)" :items="question_paper_list"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="3">
          <center>
            <v-btn dark class="green mt-3" @click="get_question_paper()">
              FETCH QUESTION PAPER
            </v-btn>
          </center>
        </v-col>

      </v-row>


    </v-card></div>
    <v-skeleton-loader v-if="loading_fetch" v-bind="attrs" type="article"></v-skeleton-loader>
    <v-data-table v-if="Array.isArray(table_data) && table_data.length" :headers="headers" :items="table_data"
      :items-per-page="5" class="elevation-1">


      <template v-slot:item.download="{ item }">
        <a :href="item.download" target="_blank"> <v-icon class="blue--text">mdi-cloud-download</v-icon></a>
      </template>
    </v-data-table>

  </div>
</template>
  
<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    snackbar: false,
    color: "",
    snackbar_msg: "",
    dialog: false,
    academic_year_list: [],
    semester_list: [],
    year_list: [],
    init_loading: false,
    dept_list: [],
    course_list: [],
    selected_ay: "",
    selected_sem: "",
    selected_year: "",
    selected_dept: "",
    selected_course: "",
    question_paper_name: "",
    selected_file: null,
    desc: "",

    academic_year_list_filter: [],
    semester_list_filter: [],
    year_list_filter: [],
    dept_list_filter: [],
    course_list_filter: [],
    selected_ay_filter: "ALL",
    selected_sem_filter: "ALL",
    selected_year_filter: "ALL",
    selected_dept_filter: "ALL",
    selected_course_filter: "ALL",
    question_paper_name_filter: "",
    question_paper_list: [],
    items: [
      {
        text: 'Dashboard',
        disabled: false,
        href: '/home',
      },
      {
        text: localStorage.getItem("jhhjbyj"),
        disabled: false,
        href: '/sublink',
      },
      {
        text: 'Question Paper',
        disabled: true,
        href: 'breadcrumbs_link_2',
      },
    ],
    table_data: [],
    current_details: {},

    loading_fetch: false,
    headers: [

      { text: 'Academic Year', value: 'ay' },
      { text: 'Name', value: 'paper_name' },
      { text: 'Course', value: 'course' },

      { text: 'Department', value: 'program' },
      { text: 'Year', value: 'year' },
      { text: 'Semester', value: 'sem' },
      { text: 'Download', value: 'download' },

    ],

  }),

  mounted() {
    this.onLoad();
  },
  methods: {


    getCurrentAcademicDetails() {


      axios
        .post("/QuestionPaper/getCurrentAcademicDetails")
        .then((res) => {
          if (res.data.msg == "200") {
            this.selected_ay_filter = res.data.ay_id;
            this.selected_sem_filter = res.data.sem_id;
            this.selected_year_filter = res.data.year_id;
            this.selected_dept_filter = res.data.program_id;
            this.get_question_paper();
          }
          else {
            // this.showSnackbar("red",res.data.msg);
          }
        })



    },




    search_question_paper(value) {


      if (value != "") {
        var params = { text: value };
        axios
          .post("/QuestionPaper/search_question_paper", params)
          .then((res) => {
            if (res.data.msg == "200") {
              this.question_paper_list = res.data.data;
            }
          })

      }
    },
    get_question_paper() {
      this.table_data = [];
      if (this.question_paper_name_filter == null)
        this.question_paper_name_filter = "";
      var params = {
        "ay": this.selected_ay_filter,
        "sem": this.selected_sem_filter,
        "year": this.selected_year_filter,
        "program": this.selected_dept_filter,
        "course": this.selected_course_filter,
        "paper_name": this.question_paper_name_filter
      };
      this.loading_fetch = true;
      axios
        .post("/QuestionPaper/get_question_paper", params)
        .then((res) => {
          if (res.data.msg == "200") {

            this.table_data = res.data.question_paper_data;
            this.loading_fetch = false;

          } else {
            this.showSnackbar("red", res.data.msg);
            this.loading_fetch = false;
          }

        });

    },




    onLoad() {

      this.init_loading = true;
      axios
        .post("/QuestionPaper/upload_question_paper_init")
        .then((res) => {
          if (res.data.msg == "200") {
            this.academic_year_list = res.data.academic_year_list;
            this.semester_list = res.data.semester_list;
            this.year_list = res.data.year_list;
            this.dept_list = res.data.dept_list;
            this.course_list = res.data.course_list;

            this.academic_year_list_filter = res.data.academic_year_list;
            this.semester_list_filter = res.data.semester_list;
            this.year_list_filter = res.data.year_list;
            this.dept_list_filter = res.data.dept_list;
            this.course_list_filter = res.data.course_list;

            this.academic_year_list_filter.push({ id: "ALL", ay: "ALL" });
            this.semester_list_filter.push({ id: "ALL", sem: "ALL" });
            this.year_list_filter.push({ id: "ALL", year: "ALL" });
            this.dept_list_filter.push({ id: "ALL", name: "ALL" });
            this.course_list_filter.push({ id: "ALL", name: "ALL" });
            this.getCurrentAcademicDetails();
            this.init_loading = false;
          }
        });

    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },



  },
};
</script>
  
  